import { defineStore, acceptHMRUpdate } from 'pinia';
import cookiesPinia from '@/plugins/cookies/pinia';
import useAppLogger from '@/composables/useAppLogger';
import axios from "@/plugins/common/axios";
import api from "@/api";

interface State {
    logging: {
        enabled: boolean,
        version: boolean,
        theme: boolean,
        axios: boolean,
        route: boolean,
        seo: boolean,
        piniaLocalStorage: boolean,
    };
    debug: {
        enabled: boolean,
        version: boolean,
        fetchServer: boolean,
    };
    editor: {
        enabled: boolean,
        article: boolean,
    };
    developer: {
        enabled: boolean,
    };
}

export const useStoreConfig = defineStore({
    id: 'app/config',

    state: (): State => ({
        logging: {
            enabled: true,
            version: false,
            theme: false,
            axios: false,
            route: false,
            seo: true,
            piniaLocalStorage: false,
        },
        debug: {
            enabled: true,
            version: true,
            fetchServer: false,
        },
        editor: {
            enabled: true,
            article: true,
        },
        developer: {
            enabled: false,
        }
    }),
    //
    // persist: {
    //     key: cookiesPinia.NAMES.CONFIG,
    //     debug: true,
    //     storage: persistedState.localStorage,
    //     // storage: persistedState.cookiesWithOptions(cookiesPinia.OPTIONS),
    //     serializer: {
    //         deserialize: (value: string) => {
    //             useAppLogger().piniaLocalStorage('config:deserialize', value, JSON.parse(value));
    //             return JSON.parse(value);
    //         },
    //         serialize: (value: any) => {
    //             useAppLogger().piniaLocalStorage('config:serialize', value, JSON.stringify(value));
    //             return JSON.stringify(value);
    //         },
    //     },
    //     beforeRestore: (ctx: any) => {
    //         useAppLogger().piniaLocalStorage(`config:beforeRestore '${ctx.store.$id}'`);
    //     },
    //     afterRestore: (ctx: any) => {
    //         useAppLogger().piniaLocalStorage(`config:afterRestore '${ctx.store.$id}'`);
    //     }
    // },

    getters: {
        enabledEditorArticle: state => state.editor.enabled && state.editor.article
    },

    actions: {
        clear() {
            localStorage.removeItem(cookiesPinia.NAMES.CONFIG);
        },
        async fetchDeveloperCheck(data: { token: string}, onSuccess: any, onError: any) {
            const self = this;
            const url = api.developer.check;
            await axios.post(url, {
                token: data.token,
            }, () => {
                self.developer.enabled = true;
                onSuccess();
            }, () => {
                self.developer.enabled = false;
                onError();
            });
        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStoreConfig, import.meta.hot));
}
