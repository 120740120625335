import { defineStore, acceptHMRUpdate } from 'pinia';

interface State {
    loaded: boolean;
    ssr: boolean;
    fontSize: string;
    theme: string;
    layout: string;
    route: {
        fullPath: string|null,
        path: string|null,
        name: string|null,
        changed: boolean,
    };
    header: boolean;
    cookies: {
        accepted: boolean,
        show: boolean,
    };
    navigation: {
        active: string|number|null,
        changed: boolean,
    };
    sidebar: object;
}

export const useStoreSystem = defineStore({
    id: 'system',

    state: (): State => ({
        loaded: false,
        ssr: true,
        fontSize: 'normal',
        theme: 'system',
        layout: 'none',
        route: {
            fullPath: null,
            path: null,
            name: null,
            changed: false,
        },
        header: true,
        sidebar: {
            canBeClosed: true,
            closed: true,
            left: true,
            right: true,
        },
        cookies: {
            accepted: false,
            show: false,
        },
        navigation: {
            active: null,
            changed: false,
        }
    }),

    getters: {
        layoutIsTable: state => state.layout === 'table',
        layoutIsEditor: state => state.layout === 'editor',
        getTheme: state => state.theme,
        getFontSize: state => state.fontSize,
        getSsr: state => state.ssr,
        server: state => !state.ssr || !process.client,
        client: state => process.client,
    },

    actions: {
        initThemeStore() {
            //this.theme = document.cookie.search('light') ? 'light' : 'dark';
            document.body.classList.add(this.theme);
        },
        toggleTheme() {
            if (this.theme === 'dark') {
                document.body.classList.remove(this.theme);
                this.theme = 'light';
            } else {
                document.body.classList.remove(this.theme);
                this.theme = 'dark';
            }
            document.cookie = `vueuse-color-scheme=${this.theme}`;
            document.body.classList.add(this.theme);
        },
        setThemeLight() {
            this.theme = 'light';
        },
        setThemeDark() {
            this.theme = 'dark';
        },
        setHeader(val: boolean) {
            this.header = val;
        },
        setLayout(layout: string) {
            this.layout = layout;
        },
        setRoute(route: object) {
            this.route = route;
        },
        setLoaded(val: boolean) {
            this.loaded = val;
        },
        setCookiesAccepted() {
            this.cookies.accepted = true;
            this.cookies.show = false;
        },
        setSidebarClosed(val: boolean) {
            this.sidebar.closed = val;
        },
        setNavigationActive(val: string|number|null) {
            this.navigation.active = val;
        },
        setNavigationChanged() {
            const self = this;
            self.navigation.changed = true;
            setTimeout(() => {
                self.navigation.changed = false;
            }, 100);
        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStoreSystem, import.meta.hot));
}
