import CryptoJS from 'crypto-js';
import moment from 'moment';
const API_PASSWORD = import.meta.env.VITE_API_PASSWORD;
const APP_KEY = import.meta.env.VITE_APP_KEY;

export default {
    headerXAuthPath() {
    // console.log(moment().utcOffset('+0300').format('YYYY-MM-DD HH:mm:ss'));
        return this.encrypt(
            API_PASSWORD + ':' + moment().utcOffset('+0300').format('YYYY-MM-DD'),
        );
    },
    /**
   * @param text
   * @returns {string}
   */
    encrypt(text) {
        const key = CryptoJS.enc.Base64.parse(APP_KEY.replace('base64:', ''));
        let iv = CryptoJS.lib.WordArray.random(16);
        const options = {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        };
        let encrypted = CryptoJS.AES.encrypt(text, key, options);
        encrypted = encrypted.toString();
        iv = CryptoJS.enc.Base64.stringify(iv);
        let result = {
            iv,
            value: encrypted,
            mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString(),
        };
        result = JSON.stringify(result);
        result = CryptoJS.enc.Utf8.parse(result);
        return CryptoJS.enc.Base64.stringify(result);
    },
    /**
   * @param hash
   * @returns {string}
   */
    decrypt(hash) {
        const key = CryptoJS.enc.Base64.parse(APP_KEY.replace('base64:', ''));
        const encryptStr = CryptoJS.enc.Base64.parse(hash);
        let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
        encryptData = JSON.parse(encryptData);
        const iv = CryptoJS.enc.Base64.parse(encryptData.iv);
        let decrypted = CryptoJS.AES.decrypt(encryptData.value, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
        return decrypted;
    },
};
