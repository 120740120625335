// import * as https from 'https';
// import * as path from 'path';
import axios from 'axios';
import crpyto from '@/plugins/common/crpyto';
import response from '@/plugins/common/response';
import serialize from '@/plugins/common/serialize';
import useAppLogger from '@/composables/useAppLogger';

const ENV = import.meta.env;
const API_SERVER_URL = import.meta.env.VITE_API_SERVER_URL;
const API_CLIENT_URL = import.meta.env.VITE_API_CLIENT_URL;
const APP_URL = import.meta.env.VITE_APP_URL;
const VITE_APP_ENV = import.meta.env.VITE_APP_ENV;
const VITE_APP_SUBDOMAIN = import.meta.env.VITE_APP_SUBDOMAIN;

export default {
    axios: null,
    responseTransform: true,

    /**
   *
   * @param {object|undefined|null} config
   */
    init(config) {
        const locale = config.locale || null;
        // const baseUrl = config.url !== undefined ? config.url : API_URL;
        // const baseUrl = '/api';
        // const baseUrl = '/localhost-api';
        const baseUrl = process.client ? API_CLIENT_URL : API_SERVER_URL;
        // console.log(API_URL);
        const token = !process.server ? localStorage.getItem('auth.token') : null;
        const headers = {
            'X-Auth-Pass': crpyto.headerXAuthPath(),
            'Accept-Language': 'ru',
            'X-App-Subdomain': VITE_APP_SUBDOMAIN,
        };
        if (token !== undefined && token !== null) {
            headers.Authorization = `Bearer ${token}`;
        }
        this.responseTransform = config.responseTransform || true;
        // const agent = new https.Agent({
        //     ca: fs.readFileSync(path.join(__dirname, './ssl/default.crt'))
        // });
        // console.log(agent);
        console.log(process.client ? 'client' : 'server', baseUrl);
        this.axios = axios.create({
            baseURL: baseUrl,
            timeout: 60000,
            withCredentials: false,
            // httpsAgent: new https.Agent({
            //     ca: fs.readFileSync(path.join(__dirname, './ssl/default.crt')),
            // }),
            // secure: true,
            // httpsAgent: agent,
            headers,
        });
        return this;
    },

    async get(url, query, successCallback, errorCallback, config) {
        const self = this;
        // console.log('ENV', ENV);
        // console.log('process.env', process.env);
        if (self.axios === null) {
            const conf = config !== undefined ? config : {};
            self.init(conf);
        }
        useAppLogger().axios('GET', url + '?' + serialize.query(query));
        await self.axios
            .get(url + '?' + serialize.query(query))
            .then((res) => {
                if (res.status !== 200) {
                    return;
                }
                const result = self.responseTransform ? response.success(res) : res;
                successCallback(result);
            })
            .catch((error) => {
                if (error) {
                    const result = self.responseTransform ? response.error(error) : error;
                    errorCallback(result);
                }
            });
    },

    async getLocal(url, query, successCallback, errorCallback) {
        const self = this;
        console.log('getLocal', APP_URL, url, VITE_APP_ENV);
        await axios
            .create({
                baseURL: APP_URL,
                timeout: 5000,
                withCredentials: false,
            })
            .get(url + '?' + serialize.query(query))
            .then((res) => {
                if (res.status !== 200) {
                    return;
                }
                const result = self.responseTransform ? response.success(res) : res;
                successCallback(result);
            })
            .catch((error) => {
                if (error) {
                    const result = self.responseTransform ? response.error(error) : error;
                    errorCallback(result);
                }
            });
    },

    /**
   *
   * @param {string} url
   * @param {object} query
   * @param {function} successCallback
   * @param {function} errorCallback
   * @returns {Promise<void>}
   */
    async post(url, query, successCallback, errorCallback) {
        const self = this;
        if (self.axios === null) {
            self.init({});
        }
        useAppLogger().axios('POST', query);
        await self.axios
            .post(url, query)
            .then((res) => {
                if (res.status !== 200) {
                    return;
                }
                const result = self.responseTransform ? response.success(res) : res;
                successCallback(result);
            })
            .catch((error) => {
                if (error) {
                    let result = self.responseTransform ? response.error(error) : error;
                    if (result === undefined) {
                        result = {};
                    }
                    errorCallback(result);
                }
            });
    },

    /**
   *
   * @param {string} url
   * @param {object} query
   * @param {function} successCallback
   * @param {function} errorCallback
   * @returns {Promise<void>}
   */
    async delete(url, query, successCallback, errorCallback) {
        const self = this;
        if (self.axios === null) {
            self.init({});
        }
        await self.axios
            .delete(url + '?' + serialize.query(query))
            .then((res) => {
                if (res.status !== 200) {
                    return;
                }
                const result = self.responseTransform ? response.success(res) : res;
                successCallback(result);
            })
            .catch((error) => {
                if (error) {
                    const result = self.responseTransform ? response.error(error) : error;
                    errorCallback(result);
                }
            });
    },
};
