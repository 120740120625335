import { useStoreConfig } from '@/stores/app/config';
// import { useAppConfig } from '#imports';

// console.log( "\u001b[1;31m Red message" );
// console.log( "\u001b[1;32m Green message" );
// console.log( "\u001b[1;33m Yellow message" );
// console.log( "\u001b[1;34m Blue message" );
// console.log( "\u001b[1;35m Purple message" );
// console.log( "\u001b[1;36m Cyan message" );
export default function useAppLogger() {
    // const appConfig = useAppConfig();
    const config = useStoreConfig();

    const seo = (...args: any) => {
        if (config.logging.enabled && config.logging.seo) {
            console.log('⌚\x1B[36m%s\x1B[0m', ' SEO:', ...args);
        }
    };
    const version = (...args: any) => {
        if (config.logging.enabled && config.logging.version) {
            console.log('⌚\x1B[36m%s\x1B[0m', ' VERSION:', ...args);
        }
    };
    const axios = (...args: any) => {
        if (config.logging.enabled && config.logging.axios) {
            console.log('⌚\x1B[33m%s\x1B[0m', ' AXIOS:', ...args);
        }
    };
    const theme = (...args: any) => {
        if (config.logging.enabled && config.logging.theme) {
            console.log('⌚\x1B[33m%s\x1B[0m', ' THEME:', ...args);
        }
    };
    const piniaLocalStorage = (...args: any) => {
        if (config.logging.enabled && config.logging.piniaLocalStorage) {
            console.log('⌚\x1B[33m%s\x1B[0m', ' PINIA LOCALSTORAGE:', ...args);
        }
    };
    const force = (...args: any) => {
        console.log('⌚\x1B[33m%s\x1B[0m', ' FORCE:', ...args);
    };

    return {
        version,
        axios,
        theme,
        piniaLocalStorage,
        force,
        seo,
    };
}
