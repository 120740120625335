import crpyto from './crpyto';

export default {
    /**
   *
   * @param response
   * @returns {*}
   */
    success(response) {
        return response.data;
    },

    /**
   *
   * @param response
   * @returns {*}
   */
    successDecrypted(response) {
        let result = crpyto.decrypt(response.data);
        result = result.replace('s:511:"', '');
        result = result.replace('";', '');
        return JSON.parse(result);
    },

    /**
   *
   * @param error
   * @returns {*}
   */
    error(error) {
        if (error.response === undefined) {
            console.log(error);
            return;
        }
        const code = error.response.status;
        if (code === 401) {
            //
        }
        if (code === 403) {
            window.location.href = '/';
        }
        if (code === 503) {
            window.location.href = '/';
        }
        return Object.assign(
            {},
            {
                status_code: code,
            },
            error.response.data,
        );
    },

    pendingAdd(array, value, type) {
        if (type !== undefined) {
            array.push(type + '_' + value);
        } else {
            array.push(value);
        }
        return array;
    },
    pendingRemove(array, value, type) {
        let index;
        if (type !== undefined) {
            index = array.indexOf(type + '_' + value);
        } else {
            index = array.indexOf(value);
        }
        if (index !== -1) {
            array.splice(index, 1);
        }
        return array;
    },
    pendingAddAction(array, id, action, key) {
        const type = action + '_' + key;
        return this.pendingAdd(array, id, type);
    },
    pendingRemoveAction(array, id, action, key) {
        const type = action + '_' + key;
        return this.pendingRemove(array, id, type);
    },
    urlWithPage(url, data) {
        if (data === undefined) {
            return url;
        }
        const page = data.page !== undefined ? data.page : 1;
        if (page !== 1) {
            url += '?page=' + page;
        }
        return url;
    },
};
