export default {
    OPTIONS: {
        path: '/',
        // sameSite: 'strict',
        maxAge: 60 * 60 * 24 * 7,
    },
    NAMES: {
        CONFIG: 'nuxt3---config',
        INTERFACE: 'nuxt3---interface',
        API_DATA: 'nuxt3---api-data',
        API_VERSION: 'nuxt3---api-version',
    }
};
